<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <h3> О ведомстве </h3>
        <p>Управление информационных технологий и муниципальной географической
          информационной системы работает над тем, чтобы возможности, которые
          дают информационные технологии сегодня, помогали каждому жителю
          Москвы получать актуальную именно для него информацию, оперативно
          решать важные вопросы и экономить самое ценное, что у него есть — время.</p>
        <a class="link__blue" href="">Документы (НПА), регламентирующие деятельность</a>
      </section>
      <section class="section">
        <h3> Руководитель </h3>
        <div class="minister__container">
          <div class="minister__img">
            <img src="https://via.placeholder.com/270x360" alt="">
          </div>
          <div class="minister__info">
            <div class="minister__name">
              Магомедрасулов <br />
              Салимхан <br />
              Магомедбегович
            </div>
            <div class="minister__description">
              Председатель комитета по спорту, туризму и делам молодежи
            </div>
            <div class="minister__contacts">
              <div class="minister__phone">
                8 (8722) 60-70-80
              </div>
              <a href="mailto:" class="link__blue">
                departament@mkala.ru
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <h3> Контактная информация </h3>
        <div class="contacts">
          <div class="contacts__list">
            <div class="contacts__item">
              <div class="contact__title">
                Адрес:
              </div>
              <div class="contact__info">
                367000, г. Махачкала, пр. Расула Гамзатова, д. 1
              </div>
            </div>
            <div class="contacts__item">
              <div class="contact__title">
                Телефоны:
              </div>
              <div class="contact__info">
                8 (8722) 60-70-80, дежурная часть: 8 (8722) 60-70-80
              </div>
            </div>
            <div v-if="false" class="contacts__item">
              <div class="contact__title">
                Официальный сайт:
              </div>
              <div class="contact__info">
                departament.mkala.ru
              </div>
              <!--            <a :href="item.url" class="contact__info" target="_blank"> {{ item.url }} </a>-->
            </div>
            <div class="contacts__item">
              <div class="contact__title">
                Электронная почта:
              </div>
              <div class="contact__info">
                departament@mkala.ru
              </div>
              <!--            <a :href="`mailto:${url}`">{{ url }}</a>-->
            </div>
          </div>
<!--          <a class="link__highlighted show-all__link" href="#">Подробнее</a>-->
        </div>
        <BannersComponent />
      </section>
    </div>
  </main>
</template>

<script>
import BannersComponent from '@/components/BannersComponent.vue'

export default {
  name: 'DepartmentPage',
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    BannersComponent
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/minister.styl"
@import "~@/styles/parts/contacts.styl"
</style>
